import type { AdminOperationProps } from '@archipro-design/aria';
import { AdminCaption } from '@archipro-design/aria';
import { ClientOnly } from 'remix-utils';

interface AdminCaptionWrapperProps {
    adminCaption: AdminOperationProps[];
}

const AdminCaptionWrapper = ({ adminCaption }: AdminCaptionWrapperProps) => {
    return (
        <ClientOnly fallback={<></>}>
            {() => <AdminCaption operations={adminCaption} />}
        </ClientOnly>
    );
};

export default AdminCaptionWrapper;
