import type { AppDisplayModeType } from '@archipro-design/aria';
import type { DirectoryItemAdmin } from '../type';
import { getAdminCaptionOperations } from './tile-admin-helpers';

interface Props {
    item?: DirectoryItemAdmin;
    canEditAdmin: boolean;
    appDisplayMode: AppDisplayModeType;
}

export const getAdminCaption = ({
    item,
    canEditAdmin,
    appDisplayMode,
}: Props) => {
    if (!appDisplayMode.desktop || !canEditAdmin || !item) {
        return null;
    }

    const operations = getAdminCaptionOperations(item);

    return operations.length > 0 ? operations : null;
};
