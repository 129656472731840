import type { AdminOperationProps } from '@archipro-design/aria';
import dayjs from 'dayjs';
import type { DirectoryItem } from 'generated/graphql';
import type { DeepPartial } from 'react-hook-form';

import type { Admin, DirectoryItemAdmin } from '../type';
import { ADMIN_CAPTION_DATE_FORMAT } from '../type';

type DirectoryItemData = DeepPartial<
    Pick<
        DirectoryItem,
        | 'ID'
        | 'CreatedByMember'
        | 'CreatedDate'
        | 'PublishedByMember'
        | 'PublishDate'
        | 'ApprovedByMember'
        | 'ApprovedDate'
        | 'HomePinned'
    >
>;

// We have to delete the member data completely in some situations due to privacy laws.
// We will return nothing for the deleted members.
// Here is the default name for those deleted members.
const DEFAULT_FIRST_NAME = 'Anonymous';
const DEFAULT_LAST_NAME = 'User';

export const getDirectoryItemAdmin = (
    data: DirectoryItemData
): DirectoryItemAdmin => {
    return {
        id: data.ID ?? 0,
        createdBy: {
            id: data.CreatedByMember?.ID ?? 0,
            firstName: data.CreatedByMember?.FirstName ?? '',
            lastName: data.CreatedByMember?.LastName ?? '',
            initials: data.CreatedByMember?.Initials ?? '',
        },
        createdDate: data.CreatedDate ?? '',
        publishedBy: {
            id: data.PublishedByMember?.ID ?? 0,
            firstName: data.PublishedByMember?.FirstName ?? '',
            lastName: data.PublishedByMember?.LastName ?? '',
            initials: data.PublishedByMember?.Initials ?? '',
        },
        publishedDate: data.PublishDate ?? '',
        approvedBy: {
            id: data.PublishedByMember?.ID ?? 0,
            firstName: data.PublishedByMember?.FirstName ?? '',
            lastName: data.PublishedByMember?.LastName ?? '',
            initials: data.PublishedByMember?.Initials ?? '',
        },
        approvedDate: data.PublishDate ?? '',
        homePinned: data.HomePinned ?? false,
    };
};

export const getAdminCaptionOperations = (data: DirectoryItemAdmin) => {
    const operations: AdminOperationProps[] = [];
    if (data.createdDate) {
        operations.push(
            getAdminCaptionOperation(
                'created',
                dayjs(data.createdDate).format(ADMIN_CAPTION_DATE_FORMAT),
                data.createdBy
            )
        );
    }

    if (data.publishedDate) {
        operations.push(
            getAdminCaptionOperation(
                'published',
                dayjs(data.publishedDate).format(ADMIN_CAPTION_DATE_FORMAT),
                data.publishedBy
            )
        );
    }

    if (data.approvedDate) {
        operations.push(
            getAdminCaptionOperation(
                'approved',
                dayjs(data.approvedDate).format(ADMIN_CAPTION_DATE_FORMAT),
                data.approvedBy
            )
        );
    }
    return operations;
};

const getAdminCaptionOperation = (
    operationType: AdminOperationProps['operation'],
    date: string,
    admin?: Admin
) => {
    const firstName = admin?.firstName || DEFAULT_FIRST_NAME;
    const lastName = admin?.lastName || DEFAULT_LAST_NAME;
    return {
        operation: operationType,
        initial:
            admin?.initials || getInitialsFromFullName(firstName, lastName),
        tooltip: {
            content: {
                children: firstName + ' ' + lastName,
            },
        },
        date,
    };
};

const getInitialsFromFullName = (firstName: string, lastName: string) => {
    let initials = '';
    if (firstName.length > 0) {
        initials += firstName.substring(0, 1).toUpperCase();
    }
    if (lastName.length > 0) {
        initials += lastName.substring(0, 1).toUpperCase();
    }
    return initials;
};
