import type { AdvertCreative } from '@modules/adverts/type';

/**
 * NOTE:
 * Copied to packages/top-navigation/src/lib/types.ts
 * Make sure to keep in sync
 */
export type Directory = 'product' | 'project' | 'professional' | 'article';

export interface DirectoryItemTile extends EditableItem {
    ID: number;
    type: Directory;
    professionalID?: number;

    // admin only info, such as AdminCaption
    admin?: DirectoryItemAdmin;
    ad?: AdvertCreative | null;
}

export const ADMIN_CAPTION_DATE_FORMAT = 'DD-MM-YY';

export interface Admin {
    id: number;
    firstName: string;
    lastName: string;
    initials: string;
}

export interface DirectoryItemAdmin {
    id: number; // item id
    createdBy?: Admin;
    createdDate?: string;
    publishedBy?: Admin;
    publishedDate?: string;
    approvedBy?: Admin;
    approvedDate?: string;
    homePinned?: boolean;
}

export interface EditableItem {
    canEdit?: boolean;
    bmEditorLink?: string;
    repositionThumbLink?: string;
    homePinned?: boolean;
}

export interface AdminItem {
    admin: DirectoryItemAdmin;
}
